<template>
  <v-container fluid>
    <v-tour :options="myOptions" name="myTour1" :steps="steps"></v-tour>
    <v-row justify="center" v-if="this.initial"> </v-row>
    <v-row justify="center" v-if="dialog">
      <v-dialog eager v-model="dialog" width="900px">
        <v-card>
          <v-card-title>
            <h4>{{ this.$store.getters["Auth/get_selected_ppm_rev"].fileName }}</h4>
            <v-spacer></v-spacer>
            <h6>
              Date :
              {{
                this.$store.getters["Auth/get_selected_ppm_rev"].publishDate
                  | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
              }}
            </h6>
          </v-card-title>
          <v-divider></v-divider>
          <div style="padding:5px;padding-left:25px;padding-bottom:5px">
            <b>Description :</b>
            {{ this.$store.getters["Auth/get_selected_ppm_rev"].description }}
          </div>
          <v-divider></v-divider>
          <VueDocPreview
            v-if="
              this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'doc' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'docx' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'xls' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'xlsx' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'XLS' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'XLSX'
            "
            :value="this.$store.getters['Auth/get_selected_ppm_rev'].fileLink"
            type="office"
          />
          <v-card-text>
            <v-row>
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'pdf'"
                allow="true"
                :src="this.$store.getters['Auth/get_selected_ppm_rev'].fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>Détail du plan de passation des marchés (PPM) publié</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Référence du PPM :</b>
            {{ this.$store.getters["Auth/get_selected_ppm"].initialPpm.title }}
            <v-spacer class="my-1"></v-spacer>
            <b>Titre du PPM :</b>
            {{ this.$store.getters["Auth/get_selected_ppm"].initialPpm.description }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure de la publication du PPM :</b>
            {{
              this.$store.getters["Auth/get_selected_ppm"].initialPpm.publishDate
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
          </v-card-text>
        </v-card>
        <v-card
          class="pa-2 mt-2"
          v-if="this.$store.getters['Auth/get_selected_ppm'].fileList.length > 0"
        >
          <v-card-text>
            <v-row>
              <v-list-item class="block text-truncate">
                <v-list-item-content>
                  <v-list-item-title
                    v-if="!initial"
                    class="primary--text text-truncate font-weight-bold"
                  >
                    Voir le fichier initial
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="initial"
                    class="primary--text text-truncate font-weight-bold"
                  >
                    Voir le fichier actuel
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="ShowInitial()">
                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title id="v-step-0"><h4>Saisir les modifications au Plan de Passation des Marchés (PPM)</h4></v-card-title>
          <v-card-text>
            <v-file-input
              v-model="ppmRev.file"
              multiple
              label="Joindre la version du PPM révisé"
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <v-textarea
              name="input-7-1"
              label="Saisir le titre de la version du PPM révisé"
              value="Décrivez le fichier"
              v-model="ppmRev.description"
            ></v-textarea>
            <v-btn
              color="primary"
              class="ma-2 white--text"
              :loading="this.$store.getters['Auth/loadPpmRev']"
              block
              @click="ppmFileAdd"
            >
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Soumettre
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card class="pa-2 mt-2">
          <v-card-title>
            <h4>Nombre et liste des plans de passation des marchés révisés</h4>
            <v-spacer></v-spacer>
            <v-chip class="ma-2">
              {{ this.$store.getters["Auth/get_selected_ppm"].fileList.length }}
            </v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item
                class="block text-truncate"
                v-for="item in this.$store.getters['Auth/get_selected_ppm'].fileList"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title class="primary--text text-truncate font-weight-bold">
                    {{ "REV N॰" + item.revision_number }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-truncate">{{
                    item.publishDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="ShowTheRevision(item.id)">
                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title
            ><h4>Traçabilité des téléchargements du plan de passation des marchés</h4>
            <v-spacer></v-spacer
            ><v-chip class="ma-2">{{
              this.$store.getters["Auth/get_selected_ppm"].number_of_download
            }}</v-chip>
          </v-card-title>
          <v-card-text>
            <v-list-item v-for="item in traceability()" :key="item.id">
              <v-list-item-content>
                <v-list-item-subtitle class="text-truncate font-weight-bold">
                  Type : {{ item.user.company ? "Entreprise" : "Individu" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="text-truncate font-weight-bold"
                  v-text="item.user.email"
                ></v-list-item-subtitle>
                <v-list-item-subtitle class="">
                  {{ item.downloaddAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="!initial">
        <v-card>
          <v-card-title>
            <h4>Aperçu du plan de passation des marchés</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height:62em !important;overflow:hidden">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'docx' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_ppm'].current_file.fileLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'pdf'"
              :src="this.$store.getters['Auth/get_selected_ppm'].current_file.fileLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="initial">
        <v-card>
          <v-card-title>
            <h4>Aperçu du plan de passation des marchés (PPM) antérieur</h4>
            <v-spacer></v-spacer>
            <h6>
              Date :
              {{
                this.$store.getters["Auth/get_selected_ppm"].initialPpm.publishDate
                  | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
              }}
            </h6>
          </v-card-title>
          <v-card-text style="height:62em !important;overflow:hidden">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                  'doc' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'docx' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'xls' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'xlsx' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'XLS' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'XLSX'
              "
              :value="InitLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="
                this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type == 'pdf'
              "
              :src="InitLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueDocPreview from "vue-doc-preview";

export default {
  components: {
    VueDocPreview
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    myOptions: {
      useKeyboardNavigation: true,
      labels: {
        buttonSkip: "Quitter",
        buttonPrevious: "Precedant",
        buttonNext: "Suivant",
        buttonStop: "Terminer"
      }
    },
    steps: [
      {
        target: "#v-step-0", // We're using document.querySelector() under the hood
        header: {
          title: "Ajouter une révision au plan de passation"
        },
        content: `Un changement s'est opéré durant le processus - Vous pouvez à tous moments rajouté des révisions`
      }
    ],
    initial: false,
    InitLink: "",
    dialog: false,
    ppmRev: {
      file: [],
      description: ""
    },
    ppm: []
  }),
  created() {
    this.$store.dispatch("Auth/getPPM", this.id);
  },
  mounted: function() {
    this.$tours["myTour1"].start();
  },
  methods: {
    getInit() {
      this.InitLink = this.$store.getters["Auth/get_selected_ppm"].initialPpm.ppmFileInitial_link;
    },
    async ShowInitial() {
      await this.getInit();
      this.initial = !this.initial;
    },
    ShowTheRevision(value) {
      this.$store.dispatch("Auth/getPPMRev", value);
      this.dialog = !this.dialog;
    },
    traceability() {
      return this.$store.getters["Auth/get_selected_ppm"].traceability;
    },
    onLoad() {
      console.log("trt");
    },
    ppmFileAdd() {
      const data = new FormData();
      console.log(this.ppmRev.file.name);
      // if (this.ppmRev.file.size > 2048 * 2048) {
      //   e.preventDefault();
      //   alert('File too big (> 2MB)');
      //   return;
      // }
      data.append("ppm_id", this.id);
      data.append("file", this.ppmRev.file[0]);
      data.append("description", this.ppmRev.description);
      this.$store.dispatch("Auth/ppmFileAdd", data);

      this.ppmRev.file = [];
      this.ppmRev.description = "";
    }
  }
};
</script>
<style>
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 58em !important;
}
</style>
