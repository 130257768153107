<template>
  <v-container fluid>
    <!-- <v-tour :options="myOptions" name="myTour" :steps="steps"></v-tour> -->
    <v-dialog v-model="this.$store.getters['Auth/isPpmDone']" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="ppm.title"
                  label="Saisir la référence du  plan de passation des marchés"></v-text-field>

                <v-text-field v-model="ppm.description" name="Description"
                  label="Saisir le titre du plan de passation des marchés" value="lorem ipsum solorem ipsta seta dolorem"
                  hint="Description de votre document"></v-text-field>
                <v-file-input v-model="ppm.file" placeholder="Charger le fichier"
                  label="Joindre le plan de passation des marchés" multiple prepend-icon="mdi-paperclip">
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">{{ text }}</v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close_ppm">ANNULER LA PUBLICATION</v-btn>
          <v-btn color="green darken-1" :loading="this.$store.getters['Auth/loadPpm']" text @click="addthisPPM">PUBLIER LE
            PPM</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-select :items="search" label="Filtre d’affichage des Plans de Passation des Marchés par année" filled id="v-step-2"
      v-model="defaultYear" v-on:change="onChange"></v-select>
    <v-data-table :headers="headers" @click:row="handleClick" item-key="this.$store.getters['Auth/getListPpm'].title"
      no-data-text="Aucun Plan de passation disponible" :items="this.$store.getters['Auth/getListPpm']"
      class="elevation-1 mb-5 px-5 pt-1">
      <template v-slot:[`item.publishDate`]="{ item }">{{
        item.publishDate | moment("YYYY")
      }}</template>
      <template v-slot:[`item.active`]="{ item }">
        <v-icon medium color="green darken-2" v-if="item.active">mdi-circle</v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-5">
          <v-toolbar-title id="v-step-1" v-show="$vuetify.breakpoint.mdAndUp">Liste détaillée des plans de passation des
            marchés</v-toolbar-title>
          <v-divider class="mx-4" inset vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-btn id="v-step-0" small color="primary" dark class="mb-2" @click="open_ppm">
            <v-icon small class="mr-2">mdi-plus</v-icon>Ajouter un nouveau PPM
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    myOptions: {
      useKeyboardNavigation: true,
      labels: {
        buttonSkip: "Quitter",
        buttonPrevious: "Precedant",
        buttonNext: "Suivant",
        buttonStop: "Terminer"
      }
    },
    steps: [
      {
        target: "#v-step-0", // We're using document.querySelector() under the hood
        header: {
          title: "Créer un plan de passation"
        },
        content: `La réalisation des objectifs du projet dépend largement des travaux en amont du projet, c’est-à-dire durant les phases de conception et de planification. L’emprunteur devrait consolider, pendant la préparation du projet, tous les accords conclus avec la Banque en matière d’acquisition de biens, travaux et services en un seul document appelé Plan de passation des marchés.`
      },
      {
        target: "#v-step-1",
        content:
          "Tous les plans de passations créer apparaitrons sur cette liste - Vous pouvez y voir plus et cliquant sur la ligne souhaité"
      },
      {
        target: "#v-step-2",
        content: "Automatiquement séléctionné - Vous pouvez de même filtrer l&apos;année cilble"
      }
    ],
    defaultYear: new Date().getFullYear(),
    search: [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1],
    publishDate: "",
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Plan de passation des marchés",
        disabled: true
      }
    ],
    dog: true,
    ppm: {
      title: "",
      description: "",
      file: []
    },
    dialog: false,
    headers: [
      {
        text: "Référence des Plans de Passation des Marchés publiée (Initial/révisé)",
        shortable: true,
        value: "title"
      },
      {
        text: "Nombre de Plans de Passation des Marchés révisé",
        align: "start",
        filterable: false,
        value: "revision_size"
      },
      {
        text: "Titre des Plans de Passation des Marchés",
        value: "description",
        sortable: false,
        filterable: false
      },
      { text: "Année de publication des Plans de Passation des Marchés", value: "publishDate", sortable: true },

    ]
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau Plan de passation des marches"
        : "Création du Plan de Passation des Marchés";
    }
  },
  created() {
    const cur_year = new Date().getFullYear();
    this.$store.dispatch("Auth/loadAllPPM", cur_year);
  },
  mounted: function () {
    this.$tours["myTour"].start();
  },
  methods: {
    ...mapActions({
      addPPM: "Auth/addPPM",
      loadAllPPM: "Auth/loadAllPPM",
      getPPM: "Auth/getPPM"
    }),
    onChange(e) {
      this.$store.dispatch("Auth/loadAllPPM", e);
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    close_ppm() {
      this.$store.commit("Auth/CLOSE_PPM", false);
    },
    open_ppm() {
      console.log(this.$store.getters["Auth/isPpmDone"]);
      this.$store.commit("Auth/CLOSE_PPM", true);
    },
    addthisPPM() {
      const data = new FormData();
      data.append("title", this.ppm.title);
      data.append("description", this.ppm.description);
      if (this.ppm.file) {
        data.append("file", this.ppm.file[0]);
      } else {
        data.append("file", null);
      }
      this.addPPM(data);

      (this.ppm.title = ""), (this.ppm.description = ""), (this.ppm.file = []);
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    handleClick(value) {
      const { id } = value;
      this.$router.push({ name: "showPpm", params: { id } });
      // this.viewDetails(value);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
